.container {
  display: flex;
  justify-content: space-between;
}
.secondBlock {
  max-width: 295px;
}
.thirdBlock {
  max-width: 285px;
}
.listItems {
  a {
    display: block;
    padding-bottom: 20px;
    color: #dedede;
    font-size: 14px;
    font-weight: 400;
  }
}
.listTitle {
  padding-bottom: 20px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

@media (min-width: 1280px) {
  .secondBlock {
    max-width: 315px;
  }
}

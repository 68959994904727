.list {
  position: relative;
  &::after {
    position: absolute;
    top: 20px;
    right: 10px;
    display: block;
    width: 9px;
    height: 9px;
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
    content: '';
    pointer-events: none;
    transform: rotate(-45deg);
    transition: all 0.2s;
  }
}

.listItems {
  overflow: hidden;
  max-height: 0vh;
  border-bottom: 1px solid #9f9f9f;
  transition: all 0.2s;
  a {
    display: block;
    padding-bottom: 20px;
    color: #dedede;
    font-size: 14px;
    font-weight: 400;
  }
}
.listTitle {
  padding: 20px 0px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}
.open {
  max-height: 50vh;
}

.animArrow {
  &::after {
    top: 25px;
    transform: rotate(135deg);
  }
}

@media (min-width: 1024px) {
}

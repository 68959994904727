.oniLogo {
  margin-top: 3px;
}

.firstColumnWrapper {
  position: relative;
  padding-bottom: 30px;

  &::after {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--color-primary-white);
    content: '';
    opacity: 0.3;
  }
}

.logoWrapper {
  display: flex;
  width: 247px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.fakeBorder {
  width: 1px;
  height: 31px;
  background-color: var(--color-primary-white);
  opacity: 0.3;
}
.medpointLogo {
  cursor: pointer;
}

@media (min-width: 768px) {
  .logoWrapper {
    width: 277px;
  }
}

@media (min-width: 1024px) {
  .firstColumnWrapper {
    &::after {
      content: none;
    }
    flex-basis: unset;
  }
}

.noEvents {
  opacity: 0.4;
  pointer-events: none;
}

.phone,
.mail {
  display: flex;
  align-items: center;
  margin-top: 20px;
  color: var(--color-primary-white);
  font-size: 16px;
}

.phone {
  margin-top: 30px;
}

.phoneIcon {
  width: 12px;
  height: 12px;
  margin-right: 9px;
}

.mailIcon {
  width: 14px;
  height: 12px;
  margin-top: 2px;
  margin-right: 9px;
}

.projectText {
  max-width: 330px;
  margin-top: 30px;
  font-size: 14px;
  line-height: 19px;
}

.contacts {
  margin-top: 20px;
  margin-bottom: 15px;
}

.iconWrapper {
  display: flex;
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  background-color: #676767;
  border-radius: 50%;
}

.partnersWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

.icon {
  margin-right: 20px;
}

@media (min-width: 768px) {

  .infoWrapper,
  .contacts {
    flex-basis: 40%;
  }

  .contacts {
    margin: 0;
  }
}

@media (min-width: 1024px) {
  .contacts {
    display: flex;
    flex-basis: unset;
    flex-direction: column;
  }

  .projectText {
    order: -1;
    margin-top: 0px;
    margin-bottom: 30px;
  }
}
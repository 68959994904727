.footer {
  position: relative;
  z-index: 2;
  display: flex;
  width: 100%;

  flex-direction: column;
  align-items: center;
  background-color: #404040;
  color: var(--color-primary-white);
}

.footerWrapper {
  position: relative;
  max-width: 1150px;
  padding: 30px 15px 40px 15px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .footerWrapper {
    width: 100%;
    max-width: 1150px;
  }
}

@media (min-width: 1024px) {
  .footerWrapper {
    padding: 40px 20px 60px 20px;
    margin: 0 auto;
  }
}

.main {
  position: relative;
  overflow: visible;
  width: 100%;
  max-width: 1440px;
  padding: 20px 15px 60px 15px;
}

@media (min-width: 375px) {
  .main {
    padding: 20px 24px 60px 24px;
  }
}

@media (min-width: 1280px) {
  .main {
    padding: 24px 40px 130px 40px;
  }
}

@media (min-width: 1500px) {
  .main {
    padding-right: 0;
    padding-left: 0;
  }
}

.noTopPadding {
  padding-top: 0;
}

.noBottomPadding {
  padding-bottom: 0;
}

.noLeftPadding {
  padding-left: 0;
}

.noRightPadding {
  padding-right: 0;
}